.ImageOne {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donate-text {
  color: white;
}

 h3{
  font-size: 3.5rem;
}

.donate-text h1 {
  font-weight: bolder;
  letter-spacing: 3%;
  font-style: italic;
  text-shadow: 0 0 10px #000;
  z-index: 9999;
  font-size: 7rem;
}

.text-box {
  width: 100%;
  text-align: justify;
  text-align: center;
  padding: 5rem;
  background-color: whitesmoke;
  height: max-content;
}

.text-box h2 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 4.5rem;
}

.text-box a {
  text-decoration: none;
  color: #0079C1;
}

.text-box a:hover {
  text-decoration: none;
  color: #00457C;
}


@media (max-width: 990px) {
    .donate-text h1{
        font-size: 4.5rem;
        font-weight: bolder;
    }
    .text-box a h2 {
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 3rem;
        text-align: center;
        font-weight: bold;
      }
      h3{
        font-size: small;
      }
}
