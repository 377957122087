/*
    All stylings for
    -Contact.js
*/

/*
    Contact.js styling
*/

.contact-wrapper {
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  width: 100vw;
  height: 100vh;
  justify-items: center;
  align-items: flex-end;
  color: white;
  text-align: start;
}

.contact-block {
  width: 75%;
  padding: 10px 10px 10px 10px;
  display: grid;
  grid-template-columns: 50% auto;
  grid-template-rows: auto;
  border: white solid 1px;
  border-radius: 10px;
  color: white;
}

.infos {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;
}

.contact-block a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.contact-block a:hover {
  text-decoration: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.socials {
  max-width: 100px;
  max-height: 100px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  align-items: center;
  margin: 5px;
  padding: 5px;
  gap: 10px;
}


._divider {
  color: black !important;
  border-style: solid;
  border-bottom-width: 1.5px;
  background-color: black;
  opacity: 1;
  border-radius: 3px;
  border-width: 1px;
  width: 60px;
}

.divider{
    color: white !important;
    border-style: solid;
    border-bottom-width: 1.5px;
    background-color: white;
    opacity: 1;
    border-radius: 3px;
    border-width: 1px;
    width: 10%;
}

.copyright {
  font-style: italic;
  justify-self: center;
  align-self: flex-end;
}

@media (max-width: 990px) {
  .contact-wrapper {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100vw;
    height: 100vh;
    justify-items: center;
    align-items: center;
    color: white;
    text-align: start;
    font-size: xx-small;
  }

  .contact-block {
    width: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    border: white solid 1px;
    border-radius: 10px;
    color: white;
  }

  .infos {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
  }

  .socials {
    max-width: 80px;
    max-height: 80px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    align-items: center;
    margin: 5px;
    padding: 5px;
    gap: 5px;
  }

  .contact-block a {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  
  .contact-block a:hover {
    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
