.bg {
  background-image: url("../imgs/universum2.jpg");
  background-repeat: repeat;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-clip: content-box;
}

.textSide {
  background-color: #fa907b;
  height: max-content;
  width: max-content;
}

/*
    ABOUT ME
*/

.box_aboutMe {
  width: min-content;
  padding-bottom: 10%;
  background-color: transparent;
}

.about {
  background-color: #1f51ff;
}
.me {
  background-color: #1f51ff;
}

.aboutMe {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  width: 100vw;
  height: 100%;
  background-color: #275395;
  color: black;
}

.a {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.empty {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

.content {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}

.a h1 {
  font-weight: 900;
  width: min-content;
  letter-spacing: 3px;
}

.b {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  display: grid;
}

.b img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.aboutMe a {
  text-decoration: none;
  color: black;
}
.aboutMe a:hover {
  text-decoration: none;
  background-color: #1f51ff;
  cursor: pointer;
  color: black;
  opacity: 0.9;
}

.container {
  z-index: 10;
  position: relative;
  width: 100vw !important;
  height: 100vh !important;
}
.container h1 {
  padding-top: 10%;
}

.abt_me_header {
  width: min-content;
  text-align: start;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 5px;
  /* margin-left: 10vw;
  margin-right: 5vw; */
}

.abt_me_description {
  text-align: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* width: 50vw!important;
  height: 100vh!important; */
  padding-top: 30px;
}

.abt_me_paragraph {
  text-align: start;
  font-size: smaller;
  /* margin-left: 10vw;
  margin-right: 5vw; */
}

.content p {
  font-size: large;
  color: white;
}

.content p a:hover {
  color: white;
  font-weight: bold;
}

@media (max-width: 990px) {
  .a h1 {
    font-weight: 900;
    width: min-content;
    font-size: 2.5rem;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .content p {
    font-size: medium;
  }


  .aboutMe {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
  }

  .a {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    height: auto;
  }
  .a p {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  /*   .empty {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  } */

  .content {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    height: 100%;
  }
  .b {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    display: grid;
  }

  .b img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

/*
    ABOUT ME
*/

/*
    1 on 1
*/

/* .video-container {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container_two {
  position: absolute;
  top: auto;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: rotate(180deg);
  transform: scaleY(-1);
}

.video-container_two::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.video-container_two video {
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

#direct_1on1 {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 100vw;
}

.direct_1on1_container {
  z-index: 10;
  color: white;
  width: 60%;
  padding-top: 10px !important;
}
.direct_1on1_container div {
  border: 5px;
  border-style: solid;
  border-color: black;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.description {
  border: none !important;
  justify-items: center;
  justify-content: center;
  justify-self: center;
  margin-left: 3vw;
  margin-right: 3vw;
}

.roundabout {
  border: none !important;
  margin: 0 !important;
}

.divider {
  color: black !important;
  border-style: solid;
  border-bottom-width: 1.5px;
  background-color: black;
  opacity: 1;
  border-radius: 3px;
  margin-left: 3vw;
  margin-right: 3vw;
}

.button {
  color: white;
  background-color: black;
  opacity: 0.95;
  display: inline-block;
  padding: 10px;
  width: max-content;
  border-radius: 5px;
}

#_1on1_title {
  text-align: start;
}

.button:hover {
  color: white;
  background-color: black;
  opacity: 0.9;
  display: inline-block;
  padding: 10px;
  width: max-content;
  border-radius: 5px;
}

.abt_me_img {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.abt_me_img img {
  width: 100%;
  height: 100%;
}

.abt_me_img:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 1);
}

#_1on1_title {
  width: 40%;
  text-align: start;
  font-size: 3.5rem;
  font-weight: bold;
}

.video-container_three {
  position: absolute;
  top: auto;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: rotate(180deg);
  transform: scaleY(-1);
}

.video-container_three video {
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container_three:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
} */

/*
    1 on 1
*/

.oneOnone {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.oneOnone p {
  font-size: small;
}

.content_oneOnone {
  width: 80vw;
  height: 80vh;
  overflow-y: hidden;
  color: white;
  position: absolute;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  justify-self: center;
}

.oneOnone video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.more_info_btn {
  width: 120px;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: black;
  border-color: transparent;
  color: white;
  z-index: 1;
  transition: .4s ease-in;
  border: none;
  position: relative;
}

.more_services_btn {
  width: 200px;
  padding: 0.5rem;
  margin-top: 50px;
  background-color: black;
  color: white;
  border-radius: 10px;
  border-color: transparent;
  z-index: 1;
  transition: .4s ease-in;
  border: none;
  position: relative;
}

.more_info_btn::before,.more_info_btn::after{
  position: absolute;
  content: "";
  z-index: -1;
}

.more_info_btn:hover {
  font-weight: bold;
  color: black;
  background: white;
  box-shadow: 0 0 5px white, 0 0 25px white, 0 0 50px white, 0 0 200px white;
}

.more_services_btn:hover {
  font-weight: bold;
  color: black;
  background: white;
  box-shadow: 0 0 5px white, 0 0 25px white, 0 0 50px white, 0 0 200px white;
}

.direct_1on1_container .group {
  padding: 20px;
  margin-top: 50px;
  border: solid black 6px;
  width: 1000px;
}

#_1on1_title {
  width: 220px;
  text-align: left;
}

.directHR{
  color: black !important;
    border-style: solid;
    border-bottom-width: 1.5px;
    background-color: black;
    opacity: 1;
    border-radius: 3px;
    border-width: 1px;
    width: 100%;
}

@media (max-width: 990px) {
  .oneOnone {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .oneOnone p {
    font-size: smaller;
  }

  .content_oneOnone {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    color: white;
    position: absolute;
    padding: 1rem;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    justify-self: center;
  }

  .oneOnone video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .more_info_btn {
    width: 120px;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: black;
    border-color: transparent;
    color: white;
  }

  .more_services_btn {
    width: 200px;
    padding: 0.5rem;
    margin-top: 50px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border-color: transparent;
  }

  .direct_1on1_container {
    width: 100%;
  }

  .direct_1on1_container .group {
    padding: 20px;
    margin-top: 50px;
    border: solid #000000 6px;
    width: 100%;
    height: auto;
  }

  #_1on1_title {
    width: 150px;
    text-align: left;
  }
}

/*
    HERO
*/

.paravideo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: contain;
  overflow-x: hidden;
}

.paravideo video {
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  transform: rotate(180deg);
  transform: scaleY(-1);
  top: auto;
  left: 0;
}

#hero_hi {
  color: white;
  font-size: 5rem;
  z-index: 9999;
  color: white;
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 10px;
}

@media (max-width: 990px) {
  #hero_hi {
    color: white;
    font-size: 3rem;
    z-index: 9999;
    color: white;
    text-decoration: underline;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 5px;
  }
}

/*
    HERO
*/
